.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 160px;
  height: 100px;
  background-color: var(--background-color-gradient-start);
  gap: 16px;
}

.app-header-icons {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.app-header-logo {
  height: 100%;
}

@media only screen and (max-width: 800px) {
  .app-header {
    height: 70px;
    padding: 8px 20px;
  }
}
