.carousel-section {
  padding: 0;
}

.carousel-image {
  margin: 0 20px;
  overflow: "hidden";
  padding: 2rem 0;
}

.carousel-image img {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
}

.carousel-root {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
