.map-container {
  padding: 0px;
  padding-top: 40px;
}

.map-link-icon {
  margin-top: 5px;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-popup-content {
  width: 120px;
  text-align: center;
}

.map-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.map-header-title {
  scroll-margin-top: 28px;
  color: var(--font-color-white);
}

.map-section-text {
  color: var(--font-color-white);
}

.map-header-link {
  align-self: center;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  transform: translateY(-30px);
}

.map-header-link:active {
  color: currentColor;
}

.map-header:hover .map-header-link {
  opacity: 100;
}

.map-popover-content {
  margin-top: 8px;
}

.country-autocomplete {
  background-color: white;
}

@media only screen and (max-width: 800px) {
  .leaflet-container {
    height: 70vh;
  }
  .map-container {
    width: 100vw;
    margin-left: -20px;
    margin-bottom: -20px;
    padding-bottom: 0;
  }
  .map-header-link {
    transform: translateY(-10px);
  }
}
