/**
 * Credit to https://www.joshwcomeau.com/css/custom-css-reset/
**/

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  --background-color-light: #ffffff;
  --background-color-dark: #2b1100;
  --background-color-gradient-start: #88de8880;
  --marker-icon-color: #00c0fa;
  --font-family: "Urbanist", sans-serif;
  --font-color-dark: #000000;
  --font-color-grey: #757575;
  --font-color-white: #ffffff;
}

img,
picture,
video,
canvas,
svg {
  display: block;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  font-family: var(--font-family);
  text-align: center;
  text-wrap: pretty;
  color: var(--font-color-dark);
  font-display: fallback;
}

p {
  color: var(--font-color-grey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

a,
a:visited {
  color: black;
}

input[type="submit"],
button {
  height: 40px;
  border-radius: 60px;
  border: 1px solid black;
  color: black;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
}

input[type="submit"]:hover,
button:hover {
  background-color: rgb(14, 30, 37);
  cursor: pointer;
}

input {
  border: 2px solid #e9ebeb;
  height: 40px;
  width: 100%;
  padding: 4px;
  margin: 0;
  border-radius: 4px;
  box-sizing: border-box;
}

select {
  border: 2px solid #e9ebeb;
  height: 40px;
  width: 100%;
  padding: 4px;
  margin: 0;
  border-radius: 4px;
  box-sizing: border-box;
}

h1 > span {
  font-size: 32px;
}
