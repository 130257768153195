.login-page {
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color-one);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 300px;
  padding: 16px;
}
