.app-footer {
  background-color: var(--background-color-light);
  padding: 60px 160px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.footer-logo-and-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none outside;
  gap: 8px;
  height: 80px;
}

.footer-links {
  display: flex;
  align-items: center;
  gap: 12px;
}

.footer-logo {
  height: 100%;
}

.icon {
  height: 28px;
}

.footer-text {
  margin-top: 24px;
  font-size: 12px;
}

@media only screen and (max-width: 800px) {
  .app-footer {
    padding: 60px 20px 60px 20px;
  }
  .footer-logo-and-links {
    width: 180px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    height: 200px;
    gap: 20px;
  }
  .footer-links {
    flex-direction: column;
  }
  .footer-logo {
    width: 180px;
  }
}
